import React from 'react';
import { Animation } from '../../components/Animation';
import { Section } from '../../components/Section';
import { useLocalDataSource } from './data';
import { PageSection } from '../../types';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as classes from './style.module.css';


export function AboutSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.allAboutMarkdown.sections[0];

    return (
        <Animation type="fadeUp">
            <Section anchor={props.sectionId} heading={props.heading}>
                <div className={classes.About}>
                    <div className={classes.Top}>
                        <div className={classes.Description} dangerouslySetInnerHTML={{ __html: data.html }} />
                        <Animation type="fadeLeft" delay={200}>
                            <GatsbyImage
                                className={classes.ImageWrapper}
                                image={data.frontmatter.imageSrc.childImageSharp.gatsbyImageData}
                                imgClassName={classes.Image}
                                alt={data.frontmatter.imageAlt || `About Image`}
                            />
                        </Animation>
                    </div>
                    {/* <Animation type="fadeLeft" delay={200}>
                        <video controls autoPlay src={require("../../../static/iherb_about.mp4").default} className={classes.Frame}>
                        </video>
                    </Animation> */}
                </div>
            </Section>
        </Animation>
    );
}
