import { graphql, useStaticQuery } from 'gatsby';
import { ImageObject } from '../../types';


interface ResponsibilitySectionQueryResult {
    allResponsibilityJson: {
        sections: {
            responsibility: {
                title: string;
                description: string;
            };
            items: Item[];
        }[];
    };
}


export interface Item {
    title: string;
    description: string;
    image: ImageObject & { linkTo?: string };
    url: string;
    visible: boolean;
}

export const useLocalDataSource = (): ResponsibilitySectionQueryResult => {
    return useStaticQuery(graphql`
        query ResponsibilitySectionQuery {
            allResponsibilityJson {
                sections: nodes {
                    responsibility {
                        title
                        description
                    }
                    items {             
                        description
                        image {
                            alt
                            src {
                                childImageSharp {
                                    gatsbyImageData(width: 400)
                                }
                            }
                        }   
                        url              
                        title
                        visible
                    }
                }
            }
        }
    `);
};
