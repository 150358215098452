import React from "react";
import { Page } from "../components/Page";
import { Seo } from "../components/Seo";
import { HeroSection } from "../sections/Hero";
import { AboutSection } from "../sections/About";
import { ProjectsSection } from "../sections/Projects";
import { CultrueSection } from "../sections/cultrue";
import { ContactSection } from "../sections/Contact";
import { ResponsibilitySection } from "../sections/responsibility";


export default function IndexPage() {
  return (
    <>
      <Seo />
      <Page useSplashScreenAnimation>
        <HeroSection sectionId="hero" />
        <AboutSection sectionId="about" heading="关于我们" />
        <ResponsibilitySection sectionId="responsibility"/>
        <ProjectsSection sectionId="introduction"/>
        <CultrueSection sectionId="culture"/>
        <ContactSection sectionId="contact" heading="聚天下英才 招贤良能士" />
      </Page>
    </>
  );
}
