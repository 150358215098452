import { graphql, useStaticQuery } from 'gatsby';
import { Project } from '../../components/Project';
import { ImageObject } from '../../types';


interface CultrueSectionQueryResult {
    allCultrueJson: {
        sections: {
            button: {
                label: string;
                url: string;
                visible: boolean;
                image: ImageObject & { linkTo?: string };

            };
            projects: Project[];
        }[];
    };
}

export const useLocalDataSource = (): CultrueSectionQueryResult => {
    return useStaticQuery(graphql`
        query CultrueSectionQuery {
            allCultrueJson {
                sections: nodes {
                    button {
                        label
                        url
                        visible
                        image {
                            alt
                            linkTo
                            src {
                                childImageSharp {
                                    gatsbyImageData(width: 2000)
                                }
                            }
                        }
                    }
                    projects {
                        category
                        description
                        image {
                            alt
                            linkTo
                            src {
                                childImageSharp {
                                    gatsbyImageData(width: 400)
                                }
                            }
                        }
                        links {
                            type
                            url
                        }
                        tags
                        title
                        visible
                    }
                }
            }
        }
    `);
};
