import React from 'react';
import { Animation } from '../../components/Animation';
import { Section } from '../../components/Section';
import { Slider } from '../../components/Slider';
import { Project } from '../../components/Project';
import { PageSection } from '../../types';
import { useLocalDataSource } from './data';
import * as classes from './style.module.css';
import { GatsbyImage } from 'gatsby-plugin-image';


export function CultrueSection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.allCultrueJson.sections[0];

    return (
        <Animation type="fadeIn">
            <Section anchor={props.sectionId} heading={props.heading}>
                <Slider additionalClasses={[classes.Projects]}>
                    {data.projects.map((project, key) => {
                        return project.visible ? <Project key={key} index={key} data={project} value={1}/> : null;
                    })}
                </Slider>
                <div className={classes.Top}>
                    <h4 className={classes.Title}>iHerb 共同价值观</h4>
                </div>
                {data.button !== undefined && data.button.image.src && data.button.visible !== false && (
                    <Animation className={classes.MoreProjects} type="fadeIn">
                        <GatsbyImage
                            className={classes.ProjectImageWrapper}
                            image={data.button.image.src.childImageSharp.gatsbyImageData}
                            alt={data.button.image.alt || `Project ${data.button.label}`}
                        />
                    </Animation>
                )}

            </Section>
        </Animation>
    );
}
