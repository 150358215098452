import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Animation } from '../../components/Animation';
import { Section } from '../../components/Section';
import { useLocalDataSource } from './data';
import { PageSection } from '../../types';
import * as classes from './style.module.css';


export function ResponsibilitySection(props: PageSection): React.ReactElement {
    const response = useLocalDataSource();
    const data = response.allResponsibilityJson.sections[0];

    return (
        <Animation type="fadeUp" >
            <Section anchor={props.sectionId} heading={props.heading}>
                <div className={classes.Project} >
                    <div className={classes.Details}>
                        <h4 className={classes.Title}>{data.responsibility.title}</h4>
                        <p>{data.responsibility.description}</p>
                    </div>
                    {data.items && data.items.map((item, key) => {
                        return (
                            <div key={key} className={classes.Item}>
                                {item.image.src && !item.image.linkTo && (
                                    <GatsbyImage
                                        className={classes.ProjectImageWrapper}
                                        image={item.image.src.childImageSharp.gatsbyImageData}
                                        alt={item.image.alt || `Project ${item.title}`
                                    }
                                    />
                                )}
                                <div className={classes.ItemDetail}>
                                    <h4 className={classes.Title}>{item.title}</h4>
                                    <p className={classes.Description}>{item.description}</p>
                                    {/* <a
                                        className={classes.Link}
                                        href={item.url}
                                        target="_blank"
                                        rel="nofollow noopener noreferrer"
                                    >
                                    阅读更多
                                    </a> */}
                                </div>
                            </div>);

                    })}
                </div>
            </Section>
        </Animation>
    );
}
